.ant-switch-checked {
  background-color: var(--primary-color) !important;
}
/* -----  Switchs ----- */
/**
  * Variants
  * positive|default -> .--custom
  * negative -> .--custom.--negative
  * orange -> .--custom.--orange
  * exxon blue 70 -> .--custom.--xblue-70
  * exxon blue 60 -> .--custom.--xblue-60
  * 
  */
/* Entregado | CLOS | Default */
.ant-switch.ant-switch-checked.--custom,
.ant-switch.ant-switch-checked.--custom.--CLOS {
  background-color: var(--blue);
}
/* Confirmación Pendiente | PEND | negative */
.ant-switch.ant-switch-checked.--custom.--negative,
.ant-switch.ant-switch-checked.--custom.--PEND {
  background-color: var(--red);
}
/* Cancelado | CANCE | red-dark */
.ant-switch.ant-switch-checked.--custom.--red-dark,
.ant-switch.ant-switch-checked.--custom.--CANCE {
  background-color: var(--red-dark);
}
/* Confimarción del cliente pendiente | CREAT | orange */
.ant-switch.ant-switch-checked.--custom.--orange,
.ant-switch.ant-switch-checked.--custom.--CREAT {
  background-color: var(--orange);
}
/* APPO | exxon blue 70 */
.ant-switch.ant-switch-checked.--custom.--xblue-70,
.ant-switch.ant-switch-checked.--custom.--APPO {
  background-color: var(--exxon-blue-70);
}
/* READY | exxon blue 60 */
.ant-switch.ant-switch-checked.--custom.--xblue-60,
.ant-switch.ant-switch-checked.--custom.--READY {
  background-color: var(--exxon-blue-60);
}
/* Recepción | RECEP | black 70 */
.ant-switch.ant-switch-checked.--custom.--black-70,
.ant-switch.ant-switch-checked.--custom.--RECEP {
  background-color: var(--black-70);
}
/* En Diagnóstico | DIAG | black 50 */
.ant-switch.ant-switch-checked.--custom.--black-50,
.ant-switch.ant-switch-checked.--custom.--DIAG {
  background-color: var(--black-50);
}
/* Reparando | PROG | black 40 */
.ant-switch.ant-switch-checked.--custom.--black-40,
.ant-switch.ant-switch-checked.--custom.--PROG {
  background-color: var(--black-40);
}
/* Disabled */
.ant-switch.ant-switch-checked.ant-switch-disabled.--custom {
  background-color: var(--black-20);
}
/* -----  Switchs ----- */
