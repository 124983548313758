.label-below-radio span.ant-radio + * {
  padding: 0;
  margin: 0;
  display: block;
}
/* ----- RadioButton ----- */
/**
  * Variants
  * positive|default -> .--custom
  * negative -> .--custom.--negative
  * orange -> .--custom.--orange
  * exxon blue 70 -> .--custom.--xblue-70
  * exxon blue 60 -> .--custom.--xblue-60
  * 
  */
/* default|positive */
/* default|positive */
.ant-radio-wrapper.--custom .ant-radio {
  transform: scale(1.4);
}
.ant-radio-wrapper.--custom .ant-radio-inner {
  border-color: var(--blue);
  border-width: 2px;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--custom .ant-radio-inner {
  border-color: var(--blue);
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--custom
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: var(--blue-dark);
  transform: scale(0.6);
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
}
.ant-radio-wrapper.--custom.--label-below {
  display: flex;
  flex-flow: column;
  place-content: center;
  justify-content: center;
  width: 100%;
  gap: 5px;
}
.ant-radio-wrapper.--custom.--label-below span {
  display: block;
  justify-content: center;
  margin: 0 auto;
}
.ant-radio-wrapper.--custom.--label-below > span::after,
.ant-radio-wrapper.--custom.--label-below::after {
  display: none;
}

/* negative */
.ant-radio-wrapper.--custom.--negative .ant-radio-inner {
  border-color: var(--red);
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--custom.--negative
  .ant-radio-inner {
  border-color: var(--red);
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--custom.--negative
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: var(--red-dark);
}

/* CREAT | orange */
.ant-radio-wrapper.--custom.--orange .ant-radio-inner {
  border-color: var(--orange) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--custom.--orange
  .ant-radio-inner {
  border-color: var(--orange) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--custom.--orange
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: var(--orange) !important;
}

/* APPO | exxon blue 70 */
.ant-radio-wrapper.--custom.--xblue-70 .ant-radio-inner {
  border-color: var(--exxon-blue-70) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--xblue-70 .ant-radio-inner {
  border-color: var(--exxon-blue-70) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--xblue-70
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: var(--exxon-blue-70) !important;
}

/* exxon blue 60 */
.ant-radio-wrapper.--custom.--xblue-60 .ant-radio-inner {
  border-color: var(--exxon-blue-60) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--xblue-60 .ant-radio-inner {
  border-color: var(--exxon-blue-60) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--xblue-60
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: var(--exxon-blue-60) !important;
}

/* black 70*/
.ant-radio-wrapper.--custom.--black-70 .ant-radio-inner {
  border-color: var(--black-70) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--black-70 .ant-radio-inner {
  border-color: var(--black-70) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--black-70
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: var(--black-70) !important;
}

/* En Diagnóstico| DIAG | black 50 */
.ant-radio-wrapper.--custom.--black-50 .ant-radio-inner {
  border-color: var(--black-50) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--black-50 .ant-radio-inner {
  border-color: var(--black-50) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--black-50
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: var(--black-50) !important;
}

/* black 40 */
.ant-radio-wrapper.--custom.--black-40 .ant-radio-inner {
  border-color: var(--black-40) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--black-40 .ant-radio-inner {
  border-color: var(--black-40) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--black-40
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: var(--black-40) !important;
}

/* disabled */
.ant-radio-wrapper.ant-radio-wrapper-disabled.--custom
  .ant-radio.ant-radio-disabled
  .ant-radio-inner {
  border-color: var(--black-10);
}
.ant-radio-wrapper.ant-radio-wrapper-checked.ant-radio-wrapper-disabled.--custom
  .ant-radio-disabled
  .ant-radio-inner::after {
  background-color: var(--black-10);
}

/*--------------------------------------------------------------------------------------------------------*/
/*
  CREAT 'Confimarción del cliente pendiente' --CREAT-color  
  PEND 'Confirmación Pendiente' --PEND-color
  APPO 'Cita confirmada' --APPO-color
  RECEP 'Recepción' --RECEP-color  
  DIAG 'En diagnósito' --DIAG-color
  QUOT 'Cotización' --QUOT-color
  PROG 'Reparando'  --PROG-color
  READY 'Terminado' --READY-color
  CLOS 'Entregado'  --CLOS-color
  CANCE 'Cancelado' --CANCE-color
*/

/* Confimarción del cliente pendiente | CREAT */
.ant-radio-wrapper.--custom.--CREAT .ant-radio-inner {
  border-color: var(--CREAT-color) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--custom.--CREAT .ant-radio-inner {
  border-color: var(--CREAT-color) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--custom.--CREAT
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: var(--CREAT-color) !important;
}

/* Confirmación Pendiente | PEND */
.ant-radio-wrapper.--custom.--PEND .ant-radio-inner {
  border-color: var(--PEND-color) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--PEND .ant-radio-inner {
  border-color: var(--PEND-color) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--PEND
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: var(--PEND-color) !important;
}

/* Cita confirmada | APPO */
.ant-radio-wrapper.--custom.--APPO .ant-radio-inner {
  border-color: var(--APPO-color) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--APPO .ant-radio-inner {
  border-color: var(--APPO-color) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--APPO
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: var(--APPO-color) !important;
}

/* Recepción | RECEP */
.ant-radio-wrapper.--custom.--RECEP .ant-radio-inner {
  border-color: var(--RECEP-color) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--RECEP .ant-radio-inner {
  border-color: var(--RECEP-color) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--RECEP
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: var(--RECEP-color) !important;
}

/* En Diagnóstico | DIAG */
.ant-radio-wrapper.--custom.--DIAG .ant-radio-inner {
  border-color: var(--DIAG-color) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--DIAG .ant-radio-inner {
  border-color: var(--DIAG-color) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--DIAG
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: var(--DIAG-color) !important;
}

/* Cotizando | QUOT */
.ant-radio-wrapper.--custom.--QUOT .ant-radio-inner {
  border-color: var(--QUOT-color) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--QUOT .ant-radio-inner {
  border-color: var(--QUOT-color) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--QUOT
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: var(--QUOT-color) !important;
}

/* Reparando | PROG */
.ant-radio-wrapper.--custom.--PROG .ant-radio-inner {
  border-color: var(--PROG-color) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--PROG .ant-radio-inner {
  border-color: var(--PROG-color) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--PROG
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: var(--PROG-color) !important;
}

/* Terminado | READY */
.ant-radio-wrapper.--custom.--READY .ant-radio-inner {
  border-color: var(--READY-color) !important;
}
.ant-radio-wrapper.--custom.--READY .ant-radio-inner {
  border-color: var(--READY-color) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--READY
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: var(--READY-color) !important;
}

/* Entregado | CLOS */
.ant-radio-wrapper.--custom.--CLOS .ant-radio-inner {
  border-color: var(--CLOS-color) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--CLOS .ant-radio-inner {
  border-color: var(--CLOS-color) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--CLOS
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: var(--CLOS-color) !important;
}

/* Cancelado | CANCE */
.ant-radio-wrapper.--custom.--CANCE .ant-radio-inner {
  border-color: var(--CANCE-color) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--CANCE .ant-radio-inner {
  border-color: var(--CANCE-color) !important;
}
.ant-radio-wrapper.ant-radio-wrapper-checked.--CANCE
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: var(--CANCE-color) !important;
}

/*--------------------------------------------------------------------------------------------------------*/
/* ----- /RadioButton ----- */
