/* ----- text ----- */
h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 1px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

.bold {
  font-weight: 600;
}
.--underlined {
  text-decoration: underline;
}
.semibold { 
  font-weight: 600;
}
.capitalize {
  text-transform: capitalize;
}
p.header-text {
  font-size: 1.1em;
}
.title-light {
  font-family: "Emprint-light";
}
b {
  font-weight: 600;
}


.inline-block,
.--inline-block {
  display: inline-block !important;
}

.red,
.--text-red {
  color: var(--red) !important;
}

.red-dark,
.--text-red-dark {
  color: var(--red-dark) !important;
}

.blue,
.--text-blue {
  color: var(--blue) !important;
}

.blue-dark,
.--text-blue-dark {
  color: var(--blue-dark) !important;
}

.orange,
.--text-orange {
  color: var(--orange) !important;
}

.exxon-blue-60,
.--text-exxon-blue-60 {
  color: var(--exxon-blue-60) !important;
}

.exxon-blue-70,
.--text-exxon-blue-70 {
  color: var(--exxon-blue-60) !important;
}

.white,
.--text-white {
  color: #fff !important;
}

.black,
.--text-black {
  color: black !important;
}

.link-a {
  color: var(--carker-blue);
}

.link-a:hover {
  color: var(--blue-dark) !important;
}

a:hover {
  color: var(--blue-dark);
}

/* ----- /text ----- */

/* ----- Alerts ----- */
.--background-APPO {
  background-color: var(--APPO-color) !important;
  color: #fff;
}

.--background-CANCE {
  background-color: var(--CANCE-color) !important;
  color: #fff;
}

.--background-CLOS {
  background-color: var(--CLOS-color) !important;
  color: #fff;
}

.--background-CREAT {
  background-color: var(--CREAT-color) !important;
  color: #fff;
}
.--background-CREAT p,
.--background-CREAT a p {
  color: var(--background-contrast-color) !important;
}

.--background-DIAG {
  background-color: var(--DIAG-color) !important;
  color: #fff;
}

.--background-QUOT {
  background-color: var(--QUOT-color) !important;
  color: #fff;
}
.--background-QUOT p,
.--background-QUOT a p {
  color: var(--background-contrast-color) !important;
}

.--background-PEND {
  background-color: var(--PEND-color) !important;
  color: #fff;
}
.--background-PEND p,
.--background-PEND a p {
  color: var(--background-contrast-color) !important;
}

.--background-PROG {
  background-color: var(--PROG-color) !important;
  color: #fff;
}

.--background-READY {
  background-color: var(--READY-color) !important;
  color: #fff;
}
.--background-READY p,
.--background-READY a p {
  color: var(--background-contrast-color) !important;
}

.--background-RECEP {
  background-color: var(--RECEP-color) !important;
  color: #fff;
}

.--background-APPO.--elevated-text,
.--background-CANCE.--elevated-text,
.--background-CLOS.--elevated-text,
.--background-CREAT.--elevated-text,
.--background-DIAG.--elevated-text,
.--background-PEND.--elevated-text,
.--background-PROG.--elevated-text,
.--background-READY.--elevated-text,
.--background-RECEP.--elevated-text,
.--background-QUOT.--elevated-text {
  text-shadow: 0.5px 0.5px 1px var(--background-contrast-color);
}

.--text-APPO {
  color: var(--APPO-color) !important;
}
.--text-CANCE {
  color: var(--CANCE-color) !important;
}
.--text-CLOS {
  color: var(--CLOS-color) !important;
}
.--text-CREAT {
  color: var(--CREAT-color) !important;
}
.--text-DIAG {
  color: var(--DIAG-color) !important;
}
.--text-QUOT {
  color: var(--QUOT-color) !important;
}
.--text-PEND {
  color: var(--PEND-color) !important;
}
.--text-PROG {
  color: var(--PROG-color) !important;
}
.--text-READY {
  color: var(--READY-color) !important;
}
.--text-RECEP {
  color: var(--RECEP-color) !important;
}
/* ----- /Alerts ----- */
