.ant-picker-input > input:disabled {
  color: rgba(0, 0, 0, 0.85) !important;
}
div.ant-picker {
  border-radius: 6px;
}
::placeholder {
  color: #a8a8a8 !important;
  font-family: "Emprint-regular" !important;
  font-size: 15px !important;
  text-transform: none;
}
.ant-form-item-control-input-content,
.ant-form-item-control-input-content input:not(.input-bottom-border),
.ant-select-selector,
.ant-input-number,
textarea.ant-input,
.ant-input-password:not(.input-bottom-border) {
  border-radius: 10px;
}
.appointment .ant-select-selector {
  overflow: auto;
}
.filter
  .ant-select-selector
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: #a8a8a8 !important;
  font-family: "Emprint-regular" !important;
  font-size: 15px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.ant-form-item-extra {
  order: -1;
  font-size: var(--font-size-base);
}
.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  background: transparent !important;
  color: "#454545" !important;
  font-size: "16px" !important;
}
.select-overflow {
  overflow: hidden;
}
.select-overflow .ant-select-selector {
  overflow-x: auto;
}
/* -----  Inputs ----- */
/* general style */
.ant-input.--custom,
.ant-input-number.--custom,
.ant-select.--custom .ant-select-selector,
.ant-picker.--custom:not(.input-bottom-border) {
  border: 1px solid var(--black-50);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: none;
  color: #000;
}
.ant-select.--custom .ant-select-selector,
.ant-select.--custom:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid var(--black-50);
  border-radius: 10px !important;
  overflow: hidden;
  box-shadow: none;
}
.ant-select-dropdown,
.ant-picker-panel-container {
  border-radius: 10px;
}

.ant-picker-ok button {
  line-height: 1;
  border-radius: 10px;
}

/* groups */
.ant-input-group-wrapper.--custom .ant-input,
.ant-input-group-wrapper.--custom .ant-input-group-addon,
.ant-input-group-wrapper.--custom .ant-input-group-addon .ant-btn {
  border-color: var(--black-50);
  border-radius: 10px;
}
.ant-input-group-wrapper.--custom .ant-input-group-addon .ant-btn {
  height: auto;
  aspect-ratio: 1/1;
  min-height: 35px;
}

.ant-input-search.--custom
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  border-radius: 0 10px 10px 0;
}

.ant-input-group-wrapper.--custom .ant-input-group > .ant-input:last-child,
.ant-input-group-wrapper.--custom .ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-group-wrapper.--custom .ant-input-group > .ant-input:first-child,
.ant-input-group-wrapper.--custom .ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* place holder */
.ant-input.--custom::placeholder,
.ant-input-number.--custom::placeholder,
.ant-picker.--custom input::placeholder,
.ant-picker.--custom .ant-picker-suffix {
  color: var(--black-40) !important;
}

/* Focus */
.ant-input:not(.ant-input-disabled).--custom:focus,
.ant-input.ant-input-focused:not(.ant-input-disabled).--custom,
.ant-input-number:not(.ant-input-number-disabled).--custom:focus,
.ant-input-number.ant-input-number-focused:not(
    .ant-input-number-disabled
  ).--custom,
.ant-select.--custom:hover .ant-select-selector,
.ant-select.ant-select-focused:not(.ant-select-disabled).--custom
  .ant-select-selector,
.ant-picker.ant-picker-focused.--custom,
.ant-picker.--custom:hover {
  border: 1px solid var(--blue);
  box-shadow: none;
}

.ant-input-group-wrapper.--custom .ant-input:not(.ant-input-disabled):focus,
.ant-input-group-wrapper.--custom
  .ant-input.ant-input-focused:not(.ant-input-disabled),
.ant-input-group-wrapper.--custom
  .ant-input-number:not(.ant-input-number-disabled):focus,
.ant-input-group-wrapper.--custom
  .ant-input-number.ant-input-number-focused:not(.ant-input-number-disabled),
.ant-input-group-wrapper.--custom .ant-select:hover .ant-select-selector,
.ant-input-group-wrapper.--custom
  .ant-select.ant-select-focused:not(.ant-select-disabled)
  .ant-select-selector {
  border-color: var(--blue);
  box-shadow: none;
}

/* Error */
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input.--custom,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input.--custom:hover,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number.--custom,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number.--custom:hover,
.ant-picker-status-error.ant-picker.--custom,
.ant-picker-status-error.ant-picker.--custom:not([disabled]):hover {
  border-color: var(--red);
  box-shadow: none;
  color: var(--red);
}

.ant-picker-status-error.ant-picker.--custom input,
.ant-picker-status-error.ant-picker.--custom:not([disabled]):hover input,
.ant-picker-status-error.ant-picker.--custom .ant-picker-suffix,
.ant-picker-status-error.ant-picker.--custom:not([disabled]):hover
  .ant-picker-suffix,
.ant-picker-status-error.ant-picker-focused input,
.ant-picker-status-error.ant-picker:focus input,
.ant-picker-status-error.ant-picker-focused input .ant-picker-suffix,
.ant-picker-status-error.ant-picker:focus input .ant-picker-suffix {
  color: var(--red);
}

.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input.--custom:focus,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input-focused.--custom,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number.--custom:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number-focused.--custom,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ).--custom
  .ant-select-selector {
  border-color: var(--red);
  box-shadow: none;
  color: var(--red);
}

/* group error */
.ant-input-group-wrapper.--custom
  .ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input,
.ant-input-group-wrapper.--custom
  .ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:hover,
.ant-input-group-wrapper.--custom
  .ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number,
.ant-input-group-wrapper.--custom
  .ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number:hover {
  border-color: var(--red);
  box-shadow: none;
  color: var(--red);
}
.ant-input-group-wrapper.--custom
  .ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:focus:not(.input-bottom-border),
.ant-input-group-wrapper.--custom
  .ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input-focused,
.ant-input-group-wrapper.--custom
  .ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number:focus,
.ant-input-group-wrapper.--custom
  .ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number-focused,
.ant-input-group-wrapper.--custom
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: var(--red) !important;
  box-shadow: none;
  color: var(--red) !important;
}

/* Warning */
.ant-input-status-warning:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input.--custom,
.ant-input-status-warning:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input.--custom:hover,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number.--custom,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number.--custom:hover,
.ant-picker-status-warning.ant-picker.--custom,
.ant-picker-status-warning.ant-picker.--custom:not([disabled]):hover {
  border-color: var(--orange) !important;
  box-shadow: none;
  color: var(--orange) !important;
}
.ant-input-status-warning:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input.--custom:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input-focused.--custom,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number.--custom:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number-focused.--custom,
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ).--custom
  .ant-select-selector {
  border-color: var(--orange) !important;
  box-shadow: none;
  color: var(--orange) !important;
}

.ant-picker-status-warning.ant-picker.--custom input,
.ant-picker-status-warning.ant-picker.--custom:not([disabled]):hover input,
.ant-picker-status-warning.ant-picker.--custom .ant-picker-suffix,
.ant-picker-status-warning.ant-picker.--custom:not([disabled]):hover
  .ant-picker-suffix,
.ant-picker-status-warning.ant-picker-focused input,
.ant-picker-status-warning.ant-picker:focus input,
.ant-picker-status-warning.ant-picker-focused input .ant-picker-suffix,
.ant-picker-status-warning.ant-picker:focus input .ant-picker-suffix {
  color: var(--orange) !important;
}

/* group warning */
.ant-input-group-wrapper.--custom
  .ant-input-status-warning:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input,
.ant-input-group-wrapper.--custom
  .ant-input-status-warning:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:hover,
.ant-input-group-wrapper.--custom
  .ant-input-number-status-warning:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number,
.ant-input-group-wrapper.--custom
  .ant-input-number-status-warning:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number:hover {
  border-color: var(--orange) !important;
  box-shadow: none;
  color: var(--orange) !important;
}
.ant-input-group-wrapper.--custom
  .ant-input-status-warning:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:focus:not(.input-bottom-border),
.ant-input-group-wrapper.--custom
  .ant-input-status-warning:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input-focused,
.ant-input-group-wrapper.--custom
  .ant-input-number-status-warning:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number:focus,
.ant-input-group-wrapper.--custom
  .ant-input-number-status-warning:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number-focused,
.ant-input-group-wrapper.--custom
  .ant-select-status-warning.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: var(--orange) !important;
  box-shadow: none;
  color: var(--orange) !important;
}

/* Disabled */
.ant-input.ant-input-disabled.--custom,
.ant-input-number.ant-input-number-disabled.--custom,
.ant-select.ant-select-disabled.--custom .ant-select-selector {
  border-color: var(--black-50);
  color: var(--black-50) !important;
  background-color: #f5f5f5 !important;
}
/* -----  /Inputs ----- */

